import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoneIcon from '@mui/icons-material/Done';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box } from '@mui/material';

import messagesService from '@lib/services/messages-service';
import DocumentIcon from '@assets/icons/Document';
import DealIcon from '@assets/icons/Deal';
import NotificationIcon from '@assets/icons/Notification';
import MessageFiles from '@organisms/Messages/MessageFiles/message-files.component';
import MessageContent from '../MessageContent/message-content.component';
import { MessageSubject } from '../MessageSubject/message-subject';
import Portal from '@molecules/Portal/portal.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';
import { OriginalMessage } from '@organisms/Dialogs/OriginalMessage/original-message.component';
import MessageIconSvg from '@assets/icons/Message';

import styles from './message.module.scss';
import { SuccessVerified } from '@organisms/Dialogs/SuccessVerification/success-verification.component';
import { FailedVerified } from '@organisms/Dialogs/FailedVerification/failed-verification.component';

const MessageIcon = ({ message }) => {
  if (!!message.Deleted) {
    return <DeleteForeverIcon style={{ backgroundColor: '#999' }} />;
  }
  switch (message.Event) {
    case 'Document_Uploaded':
    case 'Document_Version_Uploaded':
      return <DocumentIcon />;
    case 'Deal_External_Created':
    case 'Deal_RFQ_Created ':
    case 'Deal_Created':
      return <DealIcon />;
    case 'Deal_Step_Approved_And_Completed':
    case 'Deal_Step_Completed':
      return <CheckCircleOutlineOutlinedIcon className={styles.green} />;
    case 'Deal_Step_Rejected':
      return <HighlightOffIcon className={styles.red} />;
    case 'Deal_Step_Moved_To_Edit':
      return <ModeEditIcon />;
    case 'Deal_Step_Approved':
      return <DoneIcon className={styles.green} />;
    case 'Deal_Step_Approval_Submitted':
      return <PendingActionsOutlinedIcon />;
    case 'Deal_Payment_Initiated':
      return <PaymentIcon />;
    case 'Deal_Payment_Failed':
    case 'Deal_Payment_Cancelled':
    case 'Deal_Payment_Expired':
      return <CreditCardOffOutlinedIcon className={styles.red} />;
    case 'Deal_Payment_Completed':
      return <CreditScoreOutlinedIcon className={styles.green} />;
    case 'Deal_Payment_Updated':
      return <AddCardOutlinedIcon />;
    case 'Deal_Packing_Slip_Sent':
      return <MailOutlineIcon />;
    case null:
      return <MessageIconSvg />;
    default:
      return <NotificationIcon />;
  }
};

function Message({
  message,
  files,
  refresh,
  archived,
  viewAllChannels,
  setChannel,
}) {
  const { t } = useTranslation();
  const [originalMessageDialog, setOriginalMessageDialog] = useState(false);
  const [statusOfVerification, setStatusOfVerification] = useState(null);

  const labels = {
    deal: `${t('Deal')} ${t('Channel')}`,
    insurance: `${t('RequestInsurance')} ${t('Channel')}`,
    finance: `${t('RequestFinance')} ${t('Channel')}`,
    buyer_insurance: `${t('BuyerInsurance')} ${t('Channel')}`,
    fx_hedging: `${t('FxHedging')} ${t('Channel')}`,
    sea_freight: `${t('SeaFreight')} ${t('Channel')}`,
    land_transport: `${t('LandTransport')} ${t('Channel')}`,
    supervision: `${t('LoadingSupervision')} ${t('Channel')}`,
    quality_checks: `${t('QualityChecks')} ${t('Channel')}`,
    ledger: `${t('Ledger')} ${t('Channel')}`,
  };

  const deleteMessage = () => {
    messagesService.deleteMessage(message.Id).then(() => refresh());
  };

  const restoreMessage = () => {
    const payload = { Action: 'RestoreMessage' };
    messagesService.deleteMessage(message.Id, payload).then(() => refresh());
  };

  const verifyMessage = (messageId) => {
    return messagesService
      .verifyMessage(messageId)
      .then(({ data }) => {
        const message =
          data.status === 'Verified'
            ? t('SuccessfullyVerifiedWithBlockchain')
            : t('NotSuccessfullyVerifiedWithBlockchain');

        setStatusOfVerification({
          ...data,
          value: data.status,
          message,
        });
      })
      .catch(console.error)
      .finally(() => setOriginalMessageDialog(false));
  };
  const senderInfo = useMemo(() => {
    const userOrg = message.UserOrg ? `(${message.UserOrg})` : '';
    return `${t('by')} ${message.UserName} ${userOrg} | ${message.Date}`;
  }, [message.Date, message.UserName, message.UserOrg, t]);

  const originalMessageSenderInfo = useMemo(() => {
    const userOrg = message.UserOrg ? `(${message.UserOrg})` : '';
    return `${'by'} ${message.UserName} ${userOrg} | ${message.CreatedAt}`;
  }, [message.CreatedAt, message.UserName, message.UserOrg]);

  return (
    <>
      <SuccessVerified
        status={statusOfVerification}
        onClose={() => setStatusOfVerification(null)}
      />
      <FailedVerified
        status={statusOfVerification}
        onClose={() => setStatusOfVerification(null)}
      />
      {originalMessageDialog && (
        <Portal overlay={'overlay'}>
          <PopupLayout closePopUp={setOriginalMessageDialog}>
            <OriginalMessage message={message} onSubmit={verifyMessage}>
              <div
                className={`${styles.container} ${
                  styles.originalMessageContainer
                } ${!!message.Deleted && styles.containerDeleted}`}>
                <Box className={styles.messageSubject}>
                  <MessageSubject
                    parser={false}
                    message={message}
                    archived={archived}
                    onOpenOriginalMessage={() => setOriginalMessageDialog(true)}
                    onRestoreMessage={deleteMessage}
                    onDeleteMessage={restoreMessage}
                  />
                  <div className={styles.senderDate}>
                    {originalMessageSenderInfo}
                  </div>
                  <MessageContent message={message} parser={false} />
                  {files.length > 0 && (
                    <MessageFiles files={files} message={message} />
                  )}
                </Box>
              </div>
            </OriginalMessage>
          </PopupLayout>
        </Portal>
      )}
      <div
        className={`${styles.container} ${
          !!message.Deleted && styles.containerDeleted
        }`}>
        <Box>
          <div className={styles.head}>
            <MessageIcon message={message} />
            <div className={styles.line} />
          </div>
        </Box>
        <Box className={styles.messageSubject}>
          <MessageSubject
            parser={true}
            message={message}
            archived={archived}
            onOpenOriginalMessage={() => setOriginalMessageDialog(true)}
            onRestoreMessage={deleteMessage}
            onDeleteMessage={restoreMessage}
          />
          {viewAllChannels && (
            <p
              className={styles.channel}
              onClick={() => {
                setChannel(message.Channel);
              }}>
              {labels[message.Channel]}
            </p>
          )}
          <div className={styles.senderDate}>{senderInfo}</div>
          <MessageContent message={message} parser={true} />
          {files.length > 0 && <MessageFiles files={files} message={message} />}
        </Box>
      </div>
    </>
  );
}

export default memo(Message);
